import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
//@ts-ignore
import videoWalkThrough from "../../videos/headtrak-walkthrough.mp4"

const MswIndex = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="work">
        <div className="work__header">
          <div>
            <h1>HeadTrak</h1>
            <p className="work__strap">
              React Native Application &amp; NodeJS Backend
            </p>
          </div>
          <div className="work__branding">
            <StaticImage
              src="../../images/headtrak/logo.png"
              alt="HeadTrak Logo"
              width={100}
              height={100}
            />
          </div>
        </div>
      </div>

      <h2>Overview</h2>

      <p>
        <a href="https://www.mysportswellbeing.com/headtrak">Headtrak</a> is a
        mobile app to support people recovering from concussions, the app
        records key details about your concussion and guides you through a 14
        day recovery plan.
      </p>

      <h3>✨ Notable features</h3>

      <ul>
        <li>Subscription paywall &amp; IAP via Revenue Cat</li>
        <li>Local Push Notifications to remind users to check in</li>
        <li>
          Background sync to persist recovery information to remote database
        </li>
        <li>User authentication with JWT</li>
      </ul>

      <h2>💪 Responsibilities</h2>

      <p>
        As the <strong>Lead developer</strong> on the project I was responsible
        for all aspects of the technical delivery. This included;
      </p>
      <ul>
        <li>
          React Native, initially within Expo GO but later using custom
          developer clients to support IAP features
        </li>
        <li>FoalTS &amp; and Postgres backend</li>
        <li>
          Terraform to provision AWS to use ECS Fargate for our backend docker
          containers
        </li>
        <li>
          Running story mapping workshops with owner to visualise requirements
          and orchestrate a road map for delivery
        </li>
        <li>Leading a team of 1 designer and another developer</li>
      </ul>

      <h2>🚶 Walkthrough</h2>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ maxWidth: "100%", marginBottom: "1rem" }}
      >
        <source src={videoWalkThrough} type="video/mp4" />
      </video>

      <h2>🚀 Status</h2>

      <p>
        When I left the project the App was ready for the owner to release to
        the App Store and Google Play Stores
      </p>

      <StaticImage
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        src="../../images/headtrak/headtrak-pending-release.png"
        alt="HeadTrak pending release in iOS App Store"
        height={126}
        width={355}
        quality={100}
        placeholder="blurred"
      />
    </Layout>
  )
}

export default MswIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="HeadTrak" noIndex={true} />
